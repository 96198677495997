var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "service-agreement-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c(
                  "a-breadcrumb-item",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/work-oeder-setting" } },
                      [_vm._v("工单配置")]
                    ),
                  ],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("服务协议管理")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-sider",
                { attrs: { theme: "light" } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "业务分类", bordered: false } },
                    [
                      _c("a-tree", {
                        staticClass: "jtl-tree",
                        attrs: {
                          "selected-keys": _vm.selectedKeys,
                          "tree-data-simple-mode": "",
                          "tree-data": _vm.treeData,
                        },
                        on: {
                          "update:selectedKeys": function ($event) {
                            _vm.selectedKeys = $event
                          },
                          "update:selected-keys": function ($event) {
                            _vm.selectedKeys = $event
                          },
                          select: _vm.treeSelect,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "custom",
                            fn: function (item) {
                              return [
                                _c(
                                  "jtl-tooltip-component",
                                  {
                                    attrs: {
                                      title: item.title,
                                      placement: "right",
                                    },
                                  },
                                  [_c("span", [_vm._v(_vm._s(item.title))])]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-layout-content", [
                _c(
                  "div",
                  { staticClass: "page-body-margin-left-16" },
                  [
                    _c(
                      "a-card",
                      {
                        staticClass: "table-query-card",
                        attrs: { title: _vm.selectedKeys[0] + "查询" },
                      },
                      [
                        _c("jtl-table-query-component", {
                          ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME,
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-card",
                      { attrs: { title: _vm.selectedKeys[0] + "查询" } },
                      [
                        _c(
                          "jtl-button",
                          {
                            staticClass: "table-header-button",
                            attrs: { slot: "extra", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.newClick()
                              },
                            },
                            slot: "extra",
                          },
                          [_vm._v("+ 添加")]
                        ),
                        _c("a-table", {
                          attrs: {
                            loading: _vm.listLoading,
                            columns: _vm.tableColumns,
                            "data-source": _vm.listData,
                            pagination: _vm.Pagination,
                            scroll: _vm.tableScroll,
                            "row-key": "id",
                            size: _vm.TableSize,
                          },
                          on: { change: _vm.tableChange },
                          scopedSlots: _vm._u([
                            {
                              key: "action",
                              fn: function (text, record) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "jtl-view-link",
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewClick(record)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" },
                                  }),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "jtl-edit-link",
                                      on: {
                                        click: function ($event) {
                                          return _vm.editClick(record, true)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" },
                                  }),
                                  _c(
                                    "a-popconfirm",
                                    {
                                      attrs: {
                                        title: "确认删除?",
                                        "ok-text": "确认",
                                        "cancel-text": "取消",
                                      },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.deleteClick(record)
                                        },
                                      },
                                    },
                                    [
                                      _c("a", { staticClass: "jtl-del-link" }, [
                                        _vm._v("删除"),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.formDialogOK, change: _vm.onSelectChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }