import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import CommonService from '@common-src/service/common';

const levelList = [{ value: 'High', name: '高' }, { value: 'Medium', name: '中' }, { value: 'Low', name: '低' }];

export class WorkOrderSlaEntityModel extends BaseEntityModel {
    @FormControl({
        label: 'SLA名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = '';

    @FormControl({
        label: '服务时间(每周)',
        type: FormControlType.NUMBER,
        required: true,
        message: '天',
        noMessageTooltip: true
    } as FormControlOptionModel)
    timeOfDay: number = undefined;

    @FormControl({
        label: '服务时间(每天)',
        type: FormControlType.NUMBER,
        required: true,
        message: '小时',
        noMessageTooltip: true
    } as FormControlOptionModel)
    timeOfHour: number = undefined;

    @FormControl({
        label: '优先级',
        type: FormControlType.SELECT,
        options: levelList,
        required: true
    } as FormControlOptionModel)
    level: string = undefined;

    @FormControl({
        label: '响应时间',
        type: FormControlType.NUMBER,
        required: true,
        message: '分',
        noMessageTooltip: true
    } as FormControlTextModel)
    responseTime: string = undefined;

    @FormControl({
        label: '解决时间',
        type: FormControlType.NUMBER,
        required: true,
        message: '分',
        noMessageTooltip: true
    } as FormControlTextModel)
    solveTime: string = undefined;

    @FormControl({
        label: '关联服务',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getSeviceConfig,
        mode: 'multiple',
        required: true
    } as FormControlOptionModel)
    relationIds: Array<string> = [];

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    description: string = undefined;

    static getTableColumns() {
        return [
            {
                title: 'SLA名称',
                dataIndex: 'name',
                width: 500
            },
            {
                title: '服务时间',
                dataIndex: 'serviceSpan',
                customRender: (text, record, index) => {
                    return record.timeOfDay + '*' + record.timeOfHour;
                }
            },
            {
                title: '优先级',
                dataIndex: 'level',
                customRender: (text, record, index) => {
                    const match = _.filter(levelList, item => { return item.value === record.level; });
                    if (match) {
                        return match[0].name;
                    }
                    return '';
                }
            },
            {
                title: '响应时间（分）',
                dataIndex: 'responseTime'
            },
            {
                title: '解决时间（分）',
                dataIndex: 'solveTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class WorkOrderSlaQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    keyWords: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
