import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlUploadModel, FormControlOptionModel, FormControlDateModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import CommonService from '@common-src/service/common';

const serviceTypeList = [{ name: '现场', value: 'onSite' }, { name: '远程', value: 'remote' }];
export class WorkOrderUCEntityModel extends BaseEntityModel {
    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '供应商',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getSuppliers,
        required: true
    } as FormControlOptionModel)
    supplier: string = undefined;
    supplierName: string = '';

    @FormControl({
        label: '有效日期',
        type: FormControlType.SELECT_DATERANGE,
        required: true
    } as FormControlDateModel)
    dateRange: Array<any> = [];

    @FormControl({
        label: '服务方式',
        type: FormControlType.CHECKBOX_GROUP,
        required: true,
        options: serviceTypeList
    } as FormControlOptionModel)
    serviceType: Array<string> = [];

    @FormControl({
        key: 'slaIds',
        label: '关联SLA',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getAvailableUCSlas,
        optionsPromiseParam: 'id',
        mode: 'multiple',
        required: true
    } as FormControlOptionModel)
    slaIds: Array<string> = [];

    @FormControl({
        label: 'UC附件',
        type: FormControlType.UPLOAD
    } as FormControlUploadModel)
    attachments: Array<string> = [];

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    description: string = undefined;

    status: string = '';
    static getTableColumns() {
        return [
            {
                title: 'UC名称',
                dataIndex: 'name',
                width: 500
            },
            {
                title: '供应商',
                dataIndex: 'supplierName'
            },
            {
                title: '服务方式',
                dataIndex: 'serviceType',
                customRender: (text, record, index) => {
                    if (record.serviceType && record.serviceType.length > 0) {
                        const val = record.serviceType.map(item => {
                            const match = _.filter(serviceTypeList, serviceType => { return serviceType.value === item; });
                            if (match) {
                                return match[0].name;
                            }
                            return '';
                        });
                        if (val) {
                            return val.join('、');
                        } else {
                            return '';
                        }
                    } else {
                        return '';
                    }
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                customRender: (text, record, index) => {
                    return record.status ? '有效' : '无效';
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        if (_.get(json, 'effectiveDateEnd')) {
            this.dateRange = [_.get(json, 'effectiveDateStart'), _.get(json, 'effectiveDateEnd')];
        }
        const tempServiceType = _.get(json, 'serviceType');
        if (tempServiceType) {
            this.serviceType = tempServiceType.split(',');
        } else {
            this.serviceType = [];
        }
        return this;
    }

    toService() {
        const data: any = super.toService();
        if (data.dateRange && data.dateRange.length > 0) {
            data['effectiveDateStart'] = data.dateRange[0];
            data['effectiveDateEnd'] = data.dateRange[1];
            delete data.dateRange;
        }
        if (data.serviceType && data.serviceType.length > 0) {
            data.serviceType = data.serviceType.join(',');
        } else {
            data.serviceType = '';
        }
        if (data.attachments && data.attachments.length > 0) {
            data.attachments = _.map(data.attachments, att => {
                if (att.file) {
                    delete att.file;
                }
                return att;
            });
        }
        delete data.status;
        delete data.supplierName;
        delete data.typeName;

        return data;
    }
}

export class WorkOrderUCQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    keyWords: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
